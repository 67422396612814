@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(1rem + 1vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}